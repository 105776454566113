import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_router_view = _resolveComponent("router-view");
    return (_openBlock(), _createBlock(_resolveDynamicComponent($setup.layout()), null, {
        default: _withCtx(function () { return [
            _createVNode(_component_router_view)
        ]; }),
        _: 1
    }));
}
