import { createRouter, createWebHistory } from "vue-router";
var routes = [
    {
        path: "/",
        name: "home",
        meta: { layout: "main" },
        component: function () { return import("../views/HomeView.vue"); },
    },
    {
        path: "/about",
        name: "about",
        meta: { layout: "main" },
        component: function () { return import("../views/AboutView.vue"); },
    },
    {
        path: "/projects",
        name: "projects",
        meta: { layout: "main" },
        component: function () { return import("../views/ProjectsView.vue"); },
    },
    {
        path: "/stream",
        name: "stream",
        meta: { layout: "blog" },
        component: function () { return import("../views/StreamView.vue"); },
    },
    {
        path: "/blog",
        name: "blog",
        meta: { layout: "blog" },
        component: function () { return import("../views/BlogView.vue"); },
    },
    {
        path: "/recommendation",
        name: "recommendation",
        meta: { layout: "main" },
        component: function () { return import("../views/RecommendationView.vue"); },
    },
];
var router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: routes,
});
export default router;
