import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-077a3881"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "layout" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_HeaderBlog = _resolveComponent("HeaderBlog");
    var _component_router_view = _resolveComponent("router-view");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_HeaderBlog),
        _createVNode(_component_router_view)
    ]));
}
