import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_router_link = _resolveComponent("router-link");
    return (_openBlock(), _createElementBlock("header", null, [
        _createVNode(_component_router_link, {
            class: "btn btn_home",
            to: "/"
        }, {
            default: _withCtx(function () { return [
                _createTextVNode("Home")
            ]; }),
            _: 1
        }),
        ($setup.route.name === 'stream')
            ? (_openBlock(), _createBlock(_component_router_link, {
                key: 0,
                class: "btn btn_blog",
                to: "/blog"
            }, {
                default: _withCtx(function () { return [
                    _createTextVNode("Blog")
                ]; }),
                _: 1
            }))
            : ($setup.route.name === 'blog')
                ? (_openBlock(), _createBlock(_component_router_link, {
                    key: 1,
                    class: "btn btn_blog",
                    to: "/stream"
                }, {
                    default: _withCtx(function () { return [
                        _createTextVNode("Stream")
                    ]; }),
                    _: 1
                }))
                : _createCommentVNode("", true)
    ]));
}
