import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function (n) { return (_pushScopeId("data-v-693f4279"), n = n(), _popScopeId(), n); };
var _hoisted_1 = { class: "layout" };
var _hoisted_2 = { class: "container" };
var _hoisted_3 = { class: "header_wrap" };
var _hoisted_4 = { class: "content" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    var _component_HeaderMain = _resolveComponent("HeaderMain");
    var _component_router_view = _resolveComponent("router-view");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_HeaderMain)
            ]),
            _createElementVNode("section", _hoisted_4, [
                _createVNode(_component_router_view)
            ])
        ])
    ]));
}
