import { useRoute } from "vue-router";
import BlogLayout from "@/ui_app/layouts/BlogLayout.vue";
import MainLayout from "@/ui_app/layouts/MainLayout.vue";
export default {
    setup: function () {
        var route = useRoute();
        var layout = function () {
            return "".concat(route.meta.layout || 'main', "-layout");
        };
        return { layout: layout };
    },
    components: { BlogLayout: BlogLayout, MainLayout: MainLayout }
};
