<script lang="ts">
</script>

<template>
  <header>
    <div class="header_btn_wrap left_wrap">
      <router-link to="/">
        Alexandr Yaroslav
      </router-link>
    </div>
    <div class="header_btn_wrap right_wrap">
      <router-link to="/about">
        About
      </router-link>
      <span>/</span>
      <router-link to="/projects">
        Projects
      </router-link>
      <span>/</span>
      <router-link to="/stream">
        Stream
      </router-link>
      <span>/</span>
      <router-link to="/blog">
        Blog
      </router-link>
      <span>/</span>
      <router-link to="/recommendation">
        Recommendation
      </router-link>
    </div>
  </header>
</template>

<style scoped lang="scss">
header{
  position: relative;
  z-index: 99;
  padding: 5px 20px;
  margin-bottom: 300px;
  width: calc(100% - 40px);
  height: 60px;
  background: #fff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .header_btn_wrap{
    height: 100%;
    width: max-content;
    display: flex;
    align-items: center;
    justify-content: center;
    span{
      margin: 0 10px;
    }
  }
}
</style>